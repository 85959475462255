import React, { useEffect, useMemo, useState } from "react";
import OTPInput from "react-otp-input";
import logo from "../../../assets/image/logo.png";
import { useNavigate, useParams, useLocation } from "react-router-dom";
// Import style here
import style from "./OtpScreenStyle.css";
import apiCall from "../../../utils/axiosCall";
import { endPoint } from "../../../utils/endPoint";

const createInputRefs = (length) => {
  return Array.from({ length }, () => React.createRef());
};
function formatDateToLocalString(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

const ExternalInterviewOtpScreen = ({ allowedOtpLength }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [otp, setOtp] = useState("");
  const [room_id, setRoomId] = useState("");
  const [user_id, setUserId] = useState("");
  const [errorRender, setErrorRender] = useState(false);
  const [time, setTime] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const getQueryParam = (param) => {
    const params = new URLSearchParams(location.search);
    return params.get(param);
  };

  useEffect(() => {
    const error = getQueryParam("error");
    if (error) {
      setErrorRender("please check your URL.");
    } else {
      verifyInterviewLink();
      getRoomDetail();
    }
  }, []);
  const verifyInterviewLink = async () => {
    try {
      const room_id_value = getQueryParam("room");
      const user_id_value = getQueryParam("id");

      let data = {
        room_id: room_id_value,
        user_id: user_id_value,
      };
      const getVerifyLinkDetails = await apiCall({
        url: endPoint.EXTERNAL_INTERVIEW_VERIFY_LINK,
        method: "post",
        data: JSON.stringify(data),
      });
      const decryptedDetails = getVerifyLinkDetails;

      if (decryptedDetails.return_code === "fetched_data_successfully") {
        const response = decryptedDetails?.result?.data;
        const startDate = new Date(response.startTime);
        const endDate = new Date(response.endTime);
        const startDateLocal = new Date(
          startDate.getTime() - startDate.getTimezoneOffset() * 60000
        );
        const endDateLocal = new Date(
          endDate.getTime() - endDate.getTimezoneOffset() * 60000
        );
        formatDateToLocalString(startDateLocal);
        setStartTime(formatDateToLocalString(startDateLocal));
        setEndTime(formatDateToLocalString(endDateLocal));
        setTime(response.withTimezone);
      } else {
        setErrorRender("please check your URL.Link is Expired");
      }
    } catch (error) {
      console.error("Error verifying interview link:", error);
      setErrorRender(
        "An error occurred while verifying the interview link. Please try again later."
      );
    }
  };
  const getRoomDetail = async () => {
    try {
      const room_id_value = getQueryParam("room");
      const user_id_value = getQueryParam("id");
      setRoomId(room_id_value);
      setUserId(user_id_value);

      localStorage.setItem("resourceful_video_call_id", user_id_value);
      localStorage.setItem("resourceful_video_call_room", room_id_value);

      let data = {
        room_id: room_id_value,
        user_id: user_id_value,
      };

      const getRoomInfoDetail = await apiCall({
        url: endPoint.EXTERNAL_INTERVIEW_ROOM_INFO,
        method: "post",
        data: JSON.stringify(data),
      });

      if (!getRoomInfoDetail) {
        setErrorRender("API response is undefined or invalid.");
        return;
      }

      const decryptedDetails = getRoomInfoDetail;
      // console.log(decryptedDetails);
      if (decryptedDetails.return_code === "fetched_data_successfully") {
        const result = decryptedDetails?.result;
        localStorage.setItem("interview_id", result?.interview_id || "");
        localStorage.setItem("session_id", result?.session_id || "");
        localStorage.setItem(
          "is_interview_completed",
          result?.is_interview_completed || ""
        );
        localStorage.setItem(
          "interview_status",
          result?.interview_status || ""
        );
        localStorage.setItem("staff_name", result?.staff.name || "");
        localStorage.setItem("user_name", result?.user.name || "");
        localStorage.setItem("staff_uuid", result?.staff.uuid || "");
        const StaffNameCapital =
          result?.staff.name.charAt(0).toUpperCase() || "";
        const UserNameCapital = result?.user.name.charAt(0).toUpperCase() || "";

        localStorage.setItem("user_uuid", result?.user.uuid || "");
        localStorage.setItem("staff_logo", StaffNameCapital);
        localStorage.setItem("user_logo", UserNameCapital);

        var staffId = result?.staff?.uuid;
        var candidateId = result?.user?.uuid;

        if (user_id_value === staffId) {
          localStorage.setItem("userType", "staff");
          localStorage.setItem("logo", StaffNameCapital);
        } else if (user_id_value === candidateId) {
          localStorage.setItem("userType", "candidate");
          localStorage.setItem("logo", UserNameCapital);
        } else {
          console.log("");
        }
      } else {
        setErrorRender("please check your URL.");
      }
    } catch (error) {
      console.error("Error fetching room details:", error);
      setErrorRender(
        "An error occurred while fetching room details. Please try again."
      );
    }
  };

  const handleClick = async () => {
    const currentDate = new Date();
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);
    console.log("Start Time in System Time:", startDate.toLocaleString());
    console.log("End Time in System Time:", endDate.toLocaleString());
    // const startDateLocal = startDate.toLocaleString();
    // const endDateLocal = endDate.toLocaleString();
    const startDateLocal = new Date(
      startDate.getTime() - startDate.getTimezoneOffset() * 60000
    );
    const endDateLocal = new Date(
      endDate.getTime() - endDate.getTimezoneOffset() * 60000
    );

    const adjustedStartDate = new Date(
      startDateLocal.getTime() - 5 * 60 * 1000
    );

    if (currentDate >= adjustedStartDate && currentDate <= endDateLocal) {
      const staff_uuid = localStorage.getItem("staff_uuid");
      const user_uuid = localStorage.getItem("user_uuid");
      let uuid = 0;
      if (staff_uuid === user_id) uuid = staff_uuid;
      else if (user_uuid === user_id) uuid = user_uuid;
      else uuid = 0;

      let data = {
        room_id: room_id,
        user_id: uuid,
        otp: otp,
      };
      console.log(data);
      const videoCallVerifyLink = await apiCall({
        url: endPoint.EXTERNAL_INTERVIEW_VERIFY_PASSWORD,
        method: "post",
        data: JSON.stringify(data),
      });
      const decryptedDetails = videoCallVerifyLink;

      if (
        decryptedDetails.return_code === "fetched_data_successfully" &&
        decryptedDetails.result.detail === "The link is valid"
      ) {
        navigate(`/external-interview-lobby`);
      } else {
        setErrorRender("wrong OTP");
      }
    } else {
      setErrorRender(
        "Oops, something went wrong. Please check your interview time."
      );
    }
  };

  const handleChange = (otp) => {
    setErrorRender(false);
    setOtp(otp);
  };

  const handleKeyPress = (event, index) => {
    if (event.key === "Enter") {
      handleClick();
    }
  };

  return (
    <>
      <div className='container_for_otp'>
        <div className='logo_container'>
          <img src={logo} alt='otp' />
          <h2 className='mt-6 text-[17px]'>
            {time ? `${startTime} - ${endTime}` : ""}
          </h2>
          <h3 className='verification_font'>OTP Verification</h3>
        </div>
        <div className='Main_Container'>
          <div className='otp_bg_box lg:w-5/5 mx-auto'>
            <div className='input_container mx-auto'>
              <h2 className='text-center text-2xl font-semibold mb-8 main_heading_text w-[300px]'>
                Ready to Join?
              </h2>
              <h3 className='subText'>Enter Password</h3>
              <OTPInput
                onChange={handleChange}
                value={otp}
                inputStyle='inputStyle'
                numInputs={6}
                separator={<span></span>}
                renderInput={(inputProps, index) => (
                  <input
                    {...inputProps}
                    type='text'
                    onKeyPress={(event) => handleKeyPress(event, index)}
                    className='input_box'
                    style={{
                      marginRight: "10px",
                      textAlign: "center",
                      padding: "5px",
                      width: "40px",
                      height: "40px",
                      border: "1px solid #5858df",
                      borderRadius: "10px",
                    }}
                  />
                )}
              />
              {errorRender && (
                <p className='pt-2' style={{ color: "red" }}>
                  <b>{errorRender}</b>
                </p>
              )}
              <div className='text-center'>
                <button
                  className='submit_button'
                  type='submit'
                  onClick={handleClick}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className='footer'>
        <p>
          <b>Note:</b> If you find difficulties joining this video call, we
          suggest you update your browser to the latest version. Get in touch
          with us if you still find difficulties joining the call.
        </p>
      </footer>
    </>
  );
};

export default ExternalInterviewOtpScreen;
