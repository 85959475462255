import React, { useEffect, useRef } from "react";
import wave from "./../../../assets/image/wave.gif";
import write from "./../../../assets/image/write.gif";

const ExternalInterviewTranscription = ({ chat, microphone, writeAi }) => {
  const chatContainerRef = useRef(null);
  var full_name = `123`;
  const logo = localStorage.getItem("logo");
  const userType = localStorage.getItem("userType");
  const staff_name = localStorage.getItem("staff_name");
  const user_name = localStorage.getItem("user_name");
  const staff_logo = localStorage.getItem("staff_logo");
  // console.log("staff_logo", staff_logo);

  const user_logo = localStorage.getItem("user_logo");
  // console.log("user_logo", user_logo);

  const intials = logo;

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chat]);
  return (
    <div className='m-0 p-0'>
      <div className='flex flex-col flex-auto  pl-5 pt-0'>
        <div className='flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 p-4 fix-height-show'>
          <div>
            <h1 className='border-b p-5 font-bold text-[20px] font-roboto'>
              Transcript
            </h1>
          </div>
          <div
            className='flex flex-col  mb-4 pt-5 max-h-[90vh] overflow-x-hidden md:h-[815px] md:h-screen max-md:h-full'
            ref={chatContainerRef}
          >
            <div className='flex flex-col h-full '>
              <div className='grid grid-cols-12 gap-y-2 '>
                {chat.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`col-start-${
                        item.type === userType ? 4 : 1
                      } col-end-${
                        item.type === userType ? 13 : 10
                      } p-4 rounded-lg`}
                    >
                      <div
                        className={`flex ${
                          item.type === userType
                            ? "justify-start flex-row-reverse"
                            : "flex-row"
                        }`}
                      >
                        <div className='text-white flex items-center justify-center h-10 w-10 rounded-full bg-blue flex-shrink-0'>
                          {item.type === "staff"
                            ? `${staff_logo}`
                            : `${user_logo}`}
                        </div>
                        <div
                          className={`relative ${
                            item.type === userType ? "mr-3" : "ml-3"
                          } text-sm bg-white py-2 px-4 shadow rounded-xl`}
                        >
                          <div
                            className={`absolute text-xs ${
                              item.type === userType
                                ? "top-[-20px] right-0"
                                : "top-[-20px] left-0"
                            } text-gray-500 subtitle`}
                          >
                            {item.type === "staff"
                              ? `${staff_name}`
                              : `${user_name}`}
                            <span className='ml-1'>{item.createdAt}</span>
                          </div>
                          <p>{item.message}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {writeAi && (
                  <div className='col-start-1 col-end-10 p-4 rounded-lg'>
                    <div className='flex flex-row'>
                      <div className='text-white flex items-center justify-center h-10 w-10 rounded-full bg-blue flex-shrink-0'>
                        RI
                      </div>
                      <div className='relative ml-3  text-sm  py-2 px-4 bg-white shadow rounded-xl'>
                        <img src={write} width={25} />
                      </div>
                    </div>
                  </div>
                )}
                {microphone && (
                  <div className='col-start-4 col-end-13  p-4 rounded-lg'>
                    <div className='flex  justify-start flex-row-reverse'>
                      <div className='text-white flex items-center justify-center h-10 w-10 rounded-full bg-blue flex-shrink-0'>
                        {intials}
                      </div>
                      <div className='relative mr-3 ml-3 text-sm bg-white py-2 px-3 shadow rounded-xl'>
                        <img src={wave} width={25} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExternalInterviewTranscription;
