// PopupModelContext.js
import React, { createContext, useContext, useState } from "react";

const PopupModalContext = createContext();

export const usePopupModal = () => {
  return useContext(PopupModalContext);
};

export const PopupModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <PopupModalContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </PopupModalContext.Provider>
  );
};
